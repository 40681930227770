<template>
  <v-dialog v-model="dialog" width="600px" persistent>
    <v-card class="pa-4">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">
          {{ categoryID ? "Editar categoria" : "Criar categoria" }}
        </div>

        <v-spacer />

        <v-btn color="primary" :disabled="loading" small icon @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-form v-model="valid" ref="form">
        <div class="mb-6" @keypress.enter.prevent="submit()">
          <avatar-pick
            v-model="form.image"
            ref="avatarPick"
            :current="currentImage"
            :required="true"
          />

          <div class="text-body-2 mb-1">Nome</div>

          <v-text-field
            v-model="form.name"
            placeholder="Nome da categoria"
            background-color="accent"
            :rules="_rules"
            solo
            flat
          />

          <div class="text-body-2 mb-1">Marcação de popularidade</div>

          <v-switch
            v-model="form.popular"
            :label="form.popular ? 'Sim' : 'Não'"
          />

          <div class="d-flex align-center mb-1">
            <div class="text-body-1 primary--text font-weight-bold">
              Atributos
            </div>

            <v-spacer />

            <v-btn
              class="text-none"
              color="primary"
              text
              @click="addAttribute()"
            >
              Adicionar
            </v-btn>
          </div>

          <v-text-field
            v-for="(item, i) in form.attributes"
            v-model="form.attributes[i]"
            placeholder="Digite um atributo"
            background-color="accent"
            class="mb-2"
            :rules="_rules"
            :key="i"
            hide-details
            solo
            flat
          >
            <template v-slot:append>
              <v-icon small @click="removeAttribute(i)">mdi-close</v-icon>
            </template>
          </v-text-field>
        </div>

        <div class="d-flex">
          <v-spacer />

          <v-btn class="text-none rounded-lg" plain @click="close()">
            Cancelar
          </v-btn>

          <v-btn
            class="text-none rounded-lg"
            color="primary"
            :loading="loading"
            depressed
            @click="submit()"
          >
            {{ categoryID ? "Salvar alterações" : "Criar nova" }}
          </v-btn>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import { displayAlert } from "@/utils";

export default {
  name: "ManageNiche",

  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      categoryID: "",
      currentImage: "",
      form: {
        image: "",
        name: "",
        popular: "",
        attributes: [],
      },
    };
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate() || !this.$refs.avatarPick.validate())
        return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadNewCategoria",
          nome: this.form.name,
          popular: this.form.popular ? "1" : "0",
          atributos: this.form.attributes.filter((el) => !!el).join(";"),
          ...(this.form.image && { imgCapa: this.form.image }),
          ...(this.categoryID && { categoriaID: this.categoryID }),
        };

        await request(payload);

        this.displayAlert(
          this.categoryID
            ? "Categoria atualizada com sucesso"
            : "Categoria criada com sucesso"
        );

        this.$emit("update");
        this.close();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    addAttribute() {
      this.form.attributes.push("");
    },

    removeAttribute(index) {
      this.form.attributes.splice(index, 1);
    },

    open(data) {
      this.categoryID = data?.id || "";
      this.currentImage = data?.image || "";

      this.form = {
        image: "",
        name: data?.name || "",
        popular: data?.popular || "",
        attributes: data?.attributes || [],
      };

      this.dialog = true;

      if (this.$refs.form) this.$refs.form.resetValidation();
    },

    close() {
      this.dialog = false;
    },

    displayAlert,
  },
};
</script>
