<template>
  <div>
    <div class="d-flex align-center mb-4">
      <h1 class="text-h5">
        <span class="work-sans"> Gerenciar Categorias </span>
      </h1>

      <v-spacer />

      <v-btn
        color="primary"
        depressed
        rounded
        text
        @click="handleManageCategory()"
      >
        <v-icon small left> mdi-plus </v-icon>
        Nova Categoria
      </v-btn>
    </div>

    <loader-content v-if="loading" />

    <section v-else>
      <v-row>
        <v-col
          v-for="(item, i) in categories"
          cols="12"
          sm="6"
          md="4"
          xl="3"
          :key="i"
        >
          <v-card class="shadow rounded-lg pa-4" height="100%">
            <v-img
              :src="item.image"
              :aspect-ratio="16 / 9"
              class="rounded-lg foreground mb-2"
            />

            <div class="d-flex">
              <div class="primary--text text-h6 font-weight-bold">
                {{ item.name }}
              </div>

              <v-spacer />

              <v-btn
                icon
                small
                @click.stop.prevent="handleManageCategory(item)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>

    <ManageCategory ref="manageCategory" @update="getCategories()" />
  </div>
</template>

<script>
import { request } from "@/services";
import ManageCategory from "@/components/category/ManageCategory";

export default {
  name: "Categories",

  components: {
    ManageCategory,
  },

  data() {
    return {
      loading: true,
      categories: [],
    };
  },

  beforeMount() {
    this.getCategories();
  },

  methods: {
    async getCategories() {
      try {
        let payload = {
          method: "listCategoriasAdmin",
        };

        const res = await request(payload);

        this.categories = res.categorias.map((el) => ({
          id: el.id,
          name: el.nome,
          image: this.$store.getters.apiURL + el.imgCapa,
          popular: el.popular === "1",
          attributes: el.atributos.split(";"),
        }));
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    handleManageCategory(data = null) {
      this.$refs.manageCategory.open(data);
    },
  },
};
</script>

<style></style>
